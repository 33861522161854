/* Recommended styles for Splitting */
.splitting .word,
.splitting .char {
	display: inline-block;
}

/* Psuedo-element chars */
.splitting .char {
	position: relative;
}

/**
 * Populate the psuedo elements with the character to allow for expanded effects
 * Set to `display: none` by default; just add `display: block` when you want
 * to use the psuedo elements
 */
.splitting .char::before,
.splitting .char::after {
	content: attr(data-char);
	position: absolute;
	top: 0;
	left: 0;
	visibility: hidden;
	transition: inherit;
	user-select: none;
}

/* Expanded CSS Variables */

.splitting {
	/* The center word index */
	--word-center: calc((var(--word-total) - 1) / 2);
	/* The center character index */
	--char-center: calc((var(--char-total) - 1) / 2);
	/* The center character index */
	--line-center: calc((var(--line-total) - 1) / 2);
}

.splitting .word {
	/* Pecent (0-1) of the word's position */
	--word-percent: calc(var(--word-index) / var(--word-total));
	/* Pecent (0-1) of the line's position */
	--line-percent: calc(var(--line-index) / var(--line-total));
}

.splitting .char {
	/* Percent (0-1) of the char's position */
	--char-percent: calc(var(--char-index) / var(--char-total));
	/* Offset from center, positive & negative */
	--char-offset: calc(var(--char-index) - var(--char-center));
	/* Absolute distance from center, only positive */
	--distance: calc( (var(--char-offset) * var(--char-offset)) / var(--char-center) );
	/* Distance from center where -1 is the far left, 0 is center, 1 is far right */
	--distance-sine: calc(var(--char-offset) / var(--char-center));
	/* Distance from center where 1 is far left/far right, 0 is center */
	--distance-percent: calc((var(--distance) / var(--char-center)));
}
